<template>
  <v-row fill-height align="center" justify="center" no-gutters>
    <v-col cols="6">
      <v-card flat>
        <v-card-text class="d-flex justify-center">
          <v-text-field outlined :placeholder="$i18n.t('placeHolder')" rounded height="55px" class="text-h5"
            hide-details :append-icon="expand ? '' : 'mdi-magnify'"
            :append-outer-icon="expand ? 'mdi-keyboard-off-outline' : ''" @click:append-outer="expand = false"
            @click="expand = true" :value="searchKeyword" v-model="searchKeyword" clearable>
          </v-text-field>
        </v-card-text>
        <v-expand-transition>
          <v-card-text class="d-flex justify-center ma-0 pa-0" v-if="expand">
            <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="searchKeyword" class="keyboard">
            </SimpleKeyboard>
          </v-card-text>
        </v-expand-transition>
      </v-card>
    </v-col>
    <v-col cols="12" align="center" @click="expand = false">
      <v-card class="pa-4 containerBorder" width="95%" flat>
        <v-data-table hide-default-footer :items-per-page="-1" :height="viewBoxHeight" :headers="header" :items="list"
          :loading="loading" loading-text="Fetching latest data. Please wait..." fixed-header>
          <template v-slot:item.partyName="{ item }">
            <div class="my-7" v-if="item.partyName === ''"> - </div>
            <div class="my-7" v-else>
              {{ item.partyName }}
            </div>
          </template>
          <!-- <template v-slot:item.judgeName="{ item }">
            <span v-if="item.judgeName === undefined"> - </span>
            <span v-else>Judge {{ item.judgeName }}</span>
          </template> -->
          <template v-slot:item.location="{ item }">
            <v-btn @click="handleCourtLocation(item.location)" width="100%" elevation="0" color="primary" class="my-2">
              <v-icon left>mdi-gesture-tap</v-icon>
              {{ item.location }}
            </v-btn>
          </template>
          <template v-slot:item.hearingTime="{ item }">
            <span>{{ item.hearingTime | dateAndTime }}</span>
          </template>
        </v-data-table>
        <v-card-title class="mt-3 pa-0 d-flex align-center justify-center">Can't find your hearing?
          <v-btn class="mx-5 mt-2" color="primary" @click="openHearingSearchPortal()">Hearing Portal</v-btn>
        </v-card-title>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import SimpleKeyboard from "@/components/SimpleKeyboard";
import moment from "moment";
import { mapGetters } from "vuex/dist/vuex.common.js";
export default {
  name: "todayshearing",
  data() {
    return {
      searchKeyword: null,
      viewBoxHeight: 600,
      expand: false,
      loading: true,
      header: [
        {
          text: "Parties Name",
          align: "start",
          value: "name",
          width: "750",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        },
        {
          text: "Court Room Location",
          align: "center",
          value: "location",
          width: "300",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        },
        {
          text: "Court Room Floor",
          align: "center",
          value: "floor",
          width: "300",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        },
        {
          text: "Date/Time",
          align: "center",
          value: "time",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['getHearingList',"getKioskProfile", "getSessionId"]),
    list() {
      if (this.searchKeyword === "" || this.searchKeyword === null) {
        return this.getHearingList
      } else {
        let tempHearingList = []
        this.getHearingList.forEach(hearingObj => {
          if (hearingObj.name.toLowerCase().includes(this.searchKeyword.toLowerCase())) {
            tempHearingList.push(hearingObj)
          }
        })
        return tempHearingList;
      }
    }
  },
  components: {
    SimpleKeyboard
  },
  watch: {
    expand(n, o) {
      if (n) {
        this.viewBoxHeight = 370
      } else {
        this.viewBoxHeight = 600;
      }
    }
  },
  methods: {
    onChange(input) {
      this.searchKeyword = input;
    },
    onKeyPress(button) {
      if (button === "{bksp}")
        this.searchKeyword = this.searchKeyword.slice(0, -1);
    },
    onInputChange(input) {
      this.searchKeyword = input.target.value;
    },
    handleCourtLocation(location) {
      var mapLocation = this.$i18n.t("maps").filter(map => map.mapName === location)[0]
      console.log('Hearing Room location: ', mapLocation)
      var floorInfo = this.$i18n.t("floors").filter(floor => floor.floorNumber.toString() === mapLocation.mapFloor.toString())
      console.log('Floors: ', floorInfo)
      this.$store.dispatch('openImageViewer', {
        name: mapLocation.mapName,
        url: mapLocation.mapImage,
        floor: this.$i18n.t("floors").filter(floor => floor.floorNumber === mapLocation.mapFloor)[0].floorName
      })
      this.$store.dispatch('addSessionDatatoStrapi', {
        applicationId: this.getKioskProfile.data().applicationId,
        applicationSessionId: this.getSessionId,
        timeStamp: moment().toISOString(),
        actionType: 'Touch',
        sessionPayload: {
          module: "Frequently Asked Question",
          action: "View Map",
          response: mapLocation.mapName,
        }
      })
    },
    openHearingSearchPortal() {
      window.open("https://www.courts.mo.gov/cnet/nameSearch.do?newSearch=Y")
    }
  },
  mounted() {
    setTimeout(() => {
      this.$store.dispatch("fetchHearingInfo").then(response => {
        if (response) {
          this.loading = false
        }
      })
    }, 1500)

  },
  filters: {
    formatStringDate(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("LL");
    },
    formatStringTime(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("LT");
    },
  },
};
</script>

<style></style>

<i18n>
{
  "en": {
      "searchcaseno": "Search by case number",
      "searchcasename": "Search by name",
      "firstnamelabel": "First Name",
      "middlenamelabel": "Middle Name",
      "lastnamelabel": "Last Name",
      "casenolabel": "Case Number",
      "casenobutton": "Case Number Search",
      "searchnamebutton": "Search by Name",
      "casenoplaceholder": "Tap to enter case number. E.g. D-xx-xxx-xxxx-xxxxx",
      "placeHolder": "Tap to search by name"
    },
  "es":{
      "searchcaseno": "Buscar por número de caso",
      "searchcasename": "Buscar por nombre",
      "firstnamelabel": "Primer nombre",
      "middlenamelabel": "Segundo nombre",
      "lastnamelabel": "Apellido",
      "casenolabel": "Número de caso",
      "casenobutton": "Número de caso",
      "searchnamebutton": "Buscar por nombre",
      "casenoplaceholder": "Toque para ingresar el número de caso. P.ej. D-xx-xxx-xxxx-xxxxx"
    }
}
</i18n>
