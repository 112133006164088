import axios from "axios";
import i18n from "../../i18n";
import Papa from "papaparse";
export default {
  state: {
    hearingList: [],
  },
  getters: {
    getHearingList: (state) => state.hearingList,
  },
  mutations: {
    setHearingList(state, obj) {
      state.hearingList.push(obj);
    },
  },
  actions: {
    fetchHearingInfo({ commit, getters }) {
      return new Promise((resolve, reject) => {
        let data = JSON.stringify({
          userName: "kcmo",
          filePath: "/",
          apiKey:
            "ars-bb-E4uOcpcLgEIKXd-pY5x9Z-bo!7Wl-mLuQCRTmvhqzi4kYpizFdV!tdJizI?Yb75HVSlpw74t-IodAkfSW3qNk7e2OZ8Vwa-ffojXZ2oWrP-AmV0y1z3?SJKmL",
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://us-central1-kcmo-45d8e.cloudfunctions.net/apiV2/getHearingDetails/a763cd056f1b2405788443b7197e0708",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios.request(config).then((response) => {
          Papa.parse(response.data, {
            header: false,
            skipEmptyLines: true,
            complete: (result) => {
              result.data.forEach((doc) => {
                let courtroomLocation;
                let temp = i18n
                  .t("maps")
                  .filter((map) => map.courtroomLocationCode === doc[1]);
                if (temp.length === 1) {
                  courtroomLocation = temp[0].mapName;
                } else {
                  courtroomLocation = "Violation Bureau";
                }
                let hearingObj = {
                  name: doc[0],
                  location: courtroomLocation,
                  time: doc[2].trim(),
                  floor: doc[3],
                };
                console.log("Final Hearing list: ", hearingObj);
                commit("setHearingList", hearingObj);
              });
            },
          });
          resolve(true);
        });
      });
    },
  },
};
