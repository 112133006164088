import axios from "axios";
import router from "@/router";
import i18n from "../../i18n";
import moment from "moment";
export default {
  state: {
    qnaStatus: null,
    qnaKeyword: "",
    wrongAnswerCount: 0,
    court: "",
    faqCategory: "",
    isThinking: false,
    requestQna: true,
  },
  getters: {
    getQnaStatus: (state) => state.qnaStatus,
    getQnaKeyword: (state) => state.qnaKeyword,
    getWrongAnsCount: (state) => state.wrongAnswerCount,
    getCourt: (state) => state.court,
    getFaqCategory: (state) => state.faqCategory,
    getIsThinking: (state) => state.isThinking,
    getRequestQna: (state) => state.requestQna,
  },
  mutations: {
    setQnaStatus(state, status) {
      state.qnaStatus = status;
    },
    setQnaKeyword(state, keyword) {
      state.qnaKeyword = keyword;
    },
    setWrongAnswerCount(state, count) {
      state.wrongAnswerCount = count;
    },
    setCourt(state, court) {
      state.court = court;
    },
    setFaqCategory(state, category) {
      state.faqCategory = category;
    },
    setIsThinking(state, status) {
      state.isThinking = status;
    },
    setRequestQna(state, status) {
      state.requestQna = status;
    },
  },
  actions: {
    requestQnA({ getters, dispatch, commit }, transcript) {
      // Request to QNA
      if (getters.isThinking) {
        commit("setSpeechTranscript", transcript);
        commit("setTextBubbleText", transcript);
      }
      let data = JSON.stringify({
        top: 3,
        question: transcript,
        includeUnstructuredSources: true,
        confidenceScoreThreshold: "0.2",
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url:
          "https://ars-studio-en.cognitiveservices.azure.com/language/:query-knowledgebases?projectName=" +
          i18n.t("qnaEndpoint").kbName +
          "&api-version=2021-10-01&deploymentName=production",
        headers: {
          "Ocp-Apim-Subscription-Key": "da55d74bdec54b7b856aef2a8e8bbded",
          "Content-Type": "application/json",
        },
        data: data,
      };
      commit("setIsThinking", true);
      commit("setTextBubbleText", "Thinking....");
      axios
        .request(config)
        .then((response) => {
          dispatch("addSessionDatatoStrapi", {
            applicationId: getters.getKioskProfile.data().applicationId,
            applicationSessionId: getters.getSessionId,
            timeStamp: moment().toISOString(),
            actionType: "Speech",
            sessionPayload: {
              transcript: transcript,
              action: "AI Requested",
              response: response.data,
            },
          });
          if (response.data.answers[0].confidenceScore > 0.6) {
            dispatch("generateAnswer", response.data.answers[0]);
          } else if (response.data.answers[0].confidenceScore > 0.45) {
            commit("setIsThinking", false);
            if (getters.getCurrentLanguage === "en") {
              dispatch(
                "avatarSpeak",
                "I didn't quite get that, did you mean to ask following questions. Please tap your preference."
              );
            } else {
              dispatch(
                "avatarSpeak",
                "No entendía eso, querías hacer las siguientes preguntas. Por favor, toque su preferencia."
              );
            }
            let followUp = [];
            response.data.answers.forEach((output) => {
              if (output.confidenceScore > 0.45)
                followUp.push(output.questions[0]);
            });
            var headerVal;
            if (getters.getCurrentLanguage === "en") {
              headerVal = "Please Tap the option you prefer";
            } else {
              headerVal = "Por favor, toque la opción que prefiera";
            }
            dispatch("openOptionsViewer", {
              dialogHeader: headerVal,
              dialogData: followUp,
            });
          } else {
            commit("setIsThinking", false);
            // add map information here
            let mapPath = getters.getMaps.get(
              getters.getDefaultLocation.toLowerCase()
            );
            console.log("Default Map location: ", mapPath);
            dispatch("openImageViewer", {
              name: mapPath.mapName,
              url: mapPath.mapImage,
              floor: "1st Floor",
            });
            dispatch(
              "avatarSpeak",
              "Sorry! I don't know answer to this question. Please go to the Violations Bureau for more information."
            );
          }
        })
        .catch((error) => {
          commit("setIsThinking", false);
          console.log(error);
        });
    },
    generateAnswer({ dispatch, commit, getters }, result) {
      if (getters.getIsThinking) {
        commit("setQnaStatus", false);
        const metadata = result.metadata.intent;
        const ansObj = result;
        commit("setDefaultBubbleText", false);
        commit("closeOptionsViewer");
        switch (metadata) {
          case "navigate":
            var menuName = ansObj.metadata.navigateto;
            router.addRoute({
              path: menuName.toLowerCase(),
              component: () => import("@/views/" + menuName),
            });
            if (router.currentRoute.path !== menuName) {
              router.push(menuName).then(() => {
                dispatch("addSessionDatatoStrapi", {
                  applicationId: getters.getKioskProfile.data().applicationId,
                  applicationSessionId: getters.getSessionId,
                  timeStamp: moment().toISOString(),
                  actionType: "AI",
                  sessionPayload: {
                    module: "Home Page",
                    action: "navigate",
                    response: menuName,
                  },
                });
                commit("setQnaKeyword", "");
                dispatch("avatarSpeak", ansObj.answer);
                let page = i18n
                  .t("homepages")
                  .filter((page) => page.pageType[0].pathName === menuName);
                commit("setPageHeader", page[0].pageType[0].pageHeader);
              });
            } else {
              commit("setQnaKeyword", "");
              dispatch("avatarSpeak", ansObj.answer);
            }
            break;

          case "showmap":
            try {
              var mapPath = i18n
                .t("maps")
                .filter(
                  (map) => map.linkId.toString() === ansObj.metadata.linkid
                );
              dispatch("openImageViewer", {
                name: mapPath[0].mapName,
                url: mapPath[0].mapImage,
              });
              dispatch("addSessionDatatoStrapi", {
                applicationId: getters.getKioskProfile.data().applicationId,
                applicationSessionId: getters.getSessionId,
                timeStamp: moment().toISOString(),
                actionType: "AI",
                sessionPayload: {
                  module: "Frequently Asked Question",
                  action: "View Map",
                  response: mapPath[0].mapName,
                },
              });
              if (ansObj.answer.trim() !== "default") {
                dispatch(
                  "avatarSpeak",
                  ansObj.answer + " " + mapPath[0].speech
                );
              } else {
                dispatch("avatarSpeak", mapPath[0].speech);
              }
            } catch (e) {
              mapPath = getters.getMaps.get(
                getters.getDefaultLocation.toLowerCase()
              );
              dispatch("openImageViewer", {
                name: mapPath.mapName,
                url: mapPath.mapImage,
              });
              dispatch("avatarSpeak", i18n.t("mapNotFound"));
            }

            break;

          case "speak":
            dispatch("avatarSpeak", ansObj.answer);
            break;

          case "showform":
            try {
              var formName = ansObj.metadata.find(
                ({ name }) => name === "formname"
              );
              getters.getFormsEnglish.forEach((form) => {
                if (form.formName.toLowerCase() === formName.value) {
                  dispatch("openPdfViewer", {
                    url: form.formPdf,
                    name: form.formName,
                    desc: "Hello There",
                    emailLink: form.pdfUrl,
                    type: "Form",
                  });
                  dispatch("avatarSpeak", ansObj.answer);
                }
              });
            } catch (e) {
              mapPath = getters.getMaps.get(
                getters.getDefaultLocation.toLowerCase()
              );
              dispatch("openImageViewer", {
                name: mapPath.mapName,
                url: mapPath.mapImage,
              });
              dispatch("avatarSpeak", i18n.t("formNotFound"));
            }

            break;
          case "searchbykeyword":
            commit("setQnaKeyword", "");
            router.addRoute({
              path: "/faqs",
              component: () => import("@/views/faqs"),
            });
            if (router.currentRoute.path !== "/faqs") {
              router.push("/faqs");
            }
            var page = i18n
              .t("homepages")
              .filter((page) => page.pageType[0].pathName === "faqs");
            commit("setPageHeader", page[0].pageType[0].pageHeader);
            var linkId = ansObj.metadata.linkid;
            commit("setQnaKeyword", linkId);
            dispatch("avatarSpeak", ansObj.answer);
            break;

          case "showfaqcategory":
            // commit("setQnaKeyword", "")
            // var faqKeyword = ansObj.metadata.keyword

            // router.addRoute({ path: '/faqs', component: () => import('@/views/faqs') })
            // if (router.currentRoute.path !== '/faqs') {
            //     router.push('/faqs')
            // }
            // var page = i18n.t('homepages').filter(page => page.pageType[0].pathName === 'faqs')
            // commit('setPageHeader', page[0].pageType[0].pageHeader)
            // var count = i18n.t('faqCategory').filter(cat => cat.categoryName.toLowerCase() === faqKeyword)
            // if (count.length === 0) {
            //     var englishFaqList = getters.getFaqs.filter(faq => faq.locale === 'en')
            //     var linkId = englishFaqList.filter(faq => faq.question.toLowerCase().includes(faqKeyword))
            //     if (linkId.length > 0) {
            //         commit("setQnaKeyword", linkId[0].linkId)
            //     } else {
            //         mapPath = getters.getMaps.get(getters.getDefaultLocation.toLowerCase())
            //         dispatch('openImageViewer', {
            //             name: mapPath.mapName,
            //             url: mapPath.mapImage
            //         })
            //         dispatch('avatarSpeak', i18n.t('kbError'))
            //     }

            // } else {

            //     commit('setSelectedFaqCat', { name: faqKeyword, type: 'expansionPanel' })
            // }
            // dispatch('avatarSpeak', ansObj.answer);
            break;

          case "showformcategory":
            var formKeyword = ansObj.metadata.keyword;
            router.addRoute({
              path: "/forms",
              component: () => import("@/views/forms"),
            });
            if (router.currentRoute.path !== "/forms") {
              router.push("/forms");
            }
            commit("setPageHeader", "Forms");
            commit("setQnaKeyword", formKeyword);
            dispatch("avatarSpeak", ansObj.answer);
            break;

          case "showformpackets":
            var packetInfo = getters.getFormPacketsEnglish.get(
              ansObj.metadata.find(({ name }) => name === "keyword").value
            );
            dispatch("openPdfViewer", {
              url: packetInfo.packetFiles,
              name: packetInfo.packetName,
              desc: packetInfo.packetDesc,
              emailLink: packetInfo.packetLink,
              type: "Packet",
            });
            //dispatch('avatarSpeak', ansObj.answer);
            break;

          case "showhours":
            dispatch("openCourtTimingViewer", {});
            dispatch("avatarSpeak", ansObj.answer);

            break;

          case "session":
            var action = ansObj.metadata.find(
              ({ name }) => name === "action"
            ).value;
            if (action === "still there") {
              dispatch("kioskStillThere");
            } else {
              dispatch("resetKiosk");
            }
            break;

          case "followup":
            var prompts = ansObj.dialog.prompts;
            var followUp = [];
            dispatch("avatarSpeak", ansObj.answer);
            prompts.forEach((item) => followUp.push(item));
            dispatch("openOptionsViewer", {
              dialogHeader: i18n.t("selectOptionMsg"),
              dialogData: followUp,
            });
            break;
          default:
            mapPath = getters.getMaps.get(
              getters.getDefaultLocation.toLowerCase()
            );
            dispatch("openImageViewer", {
              name: mapPath.mapName,
              url: mapPath.mapImage,
            });
            dispatch("avatarSpeak", i18n.t("kbError"));
            break;
        }
      }
    },
  },
};
